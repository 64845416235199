import React, { useEffect, FormEvent, useRef, useState } from "react";
import { _t } from "../../i18n";
import BCHL from "../../assets/Bitcoin_Cash.webp";
import * as ls from "../../local-storage";
import { Config } from "../../api";
interface LandingPageProps {
  setShowLandingPage: (x: boolean) => void;
  activeUserNumber: null | number;
  config: null | Config;
}

export default (props: LandingPageProps) => {
  const { setShowLandingPage, activeUserNumber, config } = props;
  const addressRef = useRef(null);

  let payAddress: string = "";
  try {
    console.log(config);
    payAddress = config.payAddress;
  } catch (e) {}

  function copyText() {
    if (!addressRef.current) return;
    if (addressRef.current.select) {
      addressRef.current.select();
      document.execCommand("copy");
    } else {
      navigator.clipboard.writeText(payAddress);
    }
    alert(_t("landing-page.address-copied-to-clipboard"));
  }

  return (
    <article id="landing-page">
      <div id="landing-page-h2">
        <h2>{_t("landing-page.heading")}</h2>

        {/*Three Steps Container*/}
        <div id="landing-page-steps-container">
          <div className="landing-page-step">
            <div className="fh5co-person text-center">
              <h1>1</h1>
              <span
                className="fa fa-map"
                style={{ fontSize: "120px", color: "red" }}
              />
              <h2>
                <br />
                {_t("landing-page.step1")}
              </h2>
            </div>
          </div>
          <div className="landing-page-step">
            <div className="fh5co-person text-center">
              <h1>2</h1>
              <span
                className="fa fa-pencil"
                style={{ fontSize: "120px", color: "orange" }}
              />
              <h2>
                <br />
                {_t("landing-page.step2")}
              </h2>
            </div>
          </div>
          <div className="landing-page-step">
            <div className="fh5co-person text-center">
              <h1>3</h1>
              <img src={BCHL} style={{ width: "120px", height: "120px" }} />
              <h2>
                <br />
                {_t("landing-page.step3")}
              </h2>
            </div>
          </div>
          <div className="landing-page-step">
            <div className="fh5co-person text-center">
              <h1>4</h1>
              <i
                className="fa fa-wrench"
                style={{ color: "orange", fontSize: "120px" }}
              ></i>
              <h2>
                <br />
                {_t("landing-page.work")}
              </h2>
            </div>
          </div>
          <div className="landing-page-step">
            <div className="fh5co-person text-center">
              <h1>5</h1>
              <i
                className="fa fa-check"
                style={{ color: "blue", fontSize: "120px" }}
              ></i>
              <h2>
                <br />
                {_t("landing-page.step4")}
              </h2>
            </div>
          </div>
        </div>

        {payAddress !== "" && (
          <div>
            <h1>{_t("landing-page.donate")}</h1>

            {
              <div>
                {" "}
                <img src={BCHL} style={{ width: "3ch", height: "3ch" }} />
                <b>Bitcoin Cash</b>: <span ref={addressRef}>{payAddress}</span>{" "}
                <span className="fa fa-copy" onClick={copyText} />{" "}
              </div>
            }
          </div>
        )}

        <hr />

        {/*landing-page-h2*/}
      </div>

      {activeUserNumber && (
        <div className="landing-page-check-line form-line">
          <input
            id="do-not-show-again"
            type="checkbox"
            onClick={() =>
              ls.set(
                "do-no-show-landing-page-" + String(activeUserNumber),
                true,
              )
            }
          />
          <ModernLabel for="do-not-show-again">
            {_t("landing-page.do-not-show-this-again")}
          </ModernLabel>{" "}
        </div>
      )}

      <button
        id="landing-page-close-button"
        onClick={() => setShowLandingPage(false)}
      >
        Cerrar
      </button>

      <span
        id="landing-page-close-button-corner"
        className="fa fa-times"
        onClick={() => setShowLandingPage(false)}
      />
    </article>
  );
};

interface LabelProps {
  for?: string;
  children: any;
}

function ModernLabel(props: LabelProps) {
  // @ts-ignore
  return <label for={props.for}>{props.children}</label>;
}
